@import '../../variables';

.horizontal-divisor-container {
    display: flex;
    width: 100%;
    min-height: 120px;

    span {
        color: white;
        margin: auto;
        font-size: 45px;
        font-weight: bold;
        max-width: 1000px;
    }

    &.dark {
        background-color: $darkblue;
    }
    
    &.light {
        background-color: $lightblue;
    }
}
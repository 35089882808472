$primary-color: #076BFF;

@mixin shrinkLabel {
    top: -2px;
    left: 15px;
    font-size: 12px;
    color: white;
}

.form-field-container {
    position: relative;
    display: flex;
    flex-direction: column;

    &.light {
        .input-field {
            border: 1px solid lightgray;
            font-weight: bold;

            &.error {
                border-color: red;
            }
        }

        .input-label {
            color: lightgray !important;
        }

        .icon {
            .cls-1 {
                fill: lightgray;
            }
        }
    }

    &.blue {
        .input-field {
            color: $primary-color !important;
        }
    }

    .input-field {
        height: 30px;
        width: 180px;
        margin: 15px 0px;
        padding: 5px 40px;
        border-radius: 8px;
        border: none;

        &.no-icon {
            padding: 5px 15px;
            width: 230px;
        }
        
        &:focus {
            outline: none;
        }

        &:focus ~ .input-label {
            @include shrinkLabel();
        }
    }

    .icon {
        position: absolute;
        left: 15px;
        top: 27px;
        height: 15px;
        width: 15px;
    }

    .input-label {
        color: $primary-color;
        font-size: 16px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 40px;
        top: 25px;
        transition: 300ms ease all;
    
        &.shrink {
          @include shrinkLabel();
        }

        &.no-icon {
            left: 15px;
        }
    }

    .error-message {
        position: absolute;
        margin-left: 10px;
        top: 60px;
        font-size: 14px;
        font-weight: bold;
        color: red;
    }
}
$primary-color: #076BFF;
$dark-primary-color: #070434;

.filtered-result-list-container {
    display: flex;
    flex-direction: column;

    .result-filter-container {
        display: flex;
        flex-direction: row;

        .result-count {
            color: $dark-primary-color;
        }

        .dropdowns-container {
            margin-left: auto;
            display: flex;

            .filtering-menu, .ordering-menu {
                margin: auto 0px auto 20px;
            }
        }
    }

    .result-list-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 30px;
        margin: 30px 0px 50px 0px;
        width: 100%;
    }
}
@import '../../variables';

.basic-insurance-offer-container {
    background-color: $lightgrey;
    display: flex;
    width: 300px;
    height: 200px;
    margin: 0;

    .column {
        display: flex;
        flex-direction: column;
    }
    
    .info-container {
        justify-content: space-between;
        margin-right: 10px;

        .title {
            color: $lightblue;
            font-size: 17px;
        }
    
        .link {
            color: $lightblue;
            text-decoration: underline;
            cursor: pointer;
        }

        .link, .trip-duration, .age {
            font-size: 13px;
        }
    }

    .logo-container {
        margin: auto 0 auto auto;

        .partner-logo {
            max-width: 120px;

            &.universal-assistance {
                max-width: 80px;
            }
        }
    }
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 100px auto;

  .tail-loader {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
    border-top-color: #076BFF;
    animation: tail-loader 1s linear infinite;
  }
  
  @keyframes tail-loader {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
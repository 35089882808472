@import '../../variables';

.purchase-details-container {
    background-color: $lightgrey;
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0;
    row-gap: 20px;

    .title, .total {
        margin: 0;
    }

    .title {
        color: $lightblue;
    }

    .purchase-details {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .row {
        display: flex;
        flex-direction: row;

        .value {
            margin-left: auto;
        }

        span {
            font-size: 13px;
        }
    }

    .total {
        color: $darkblue;
    }

    .horizontal-divider {
        background-color: $lightblue;
    }
}
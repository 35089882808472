.insurance-purchase-tutorial-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 50px auto;
    width: 1000px;
    column-gap: 50px;

    .red-title {
        .title {
            color: #A2443A;
        }
    }

    .yellow-title {
        .title {
            color: #CCA002;
        }
    }

    .green-title {
        .title {
            color: #005F36;
        }
    }

    .blue-title {
        .title {
            color: #3B7AA8;
        }
    }
}
.faq-dropdown-container {
    $b-radius: 15px;
    $height: 80px;
    $width: 575px;
    $b-size: 2px;
    width: $width;
    margin-bottom: 40px;

    .dropdown {
        display: flex;
        flex-direction: column;
        border-radius: $b-radius;
        width: $width;
        min-height: $height;
        border: $b-size solid lightgray;

        &.expanded {
            z-index: 1;

            .answer-container {
                max-height: 300px;
            }

            .action {
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }

        .question-container {
            display: flex;
            flex-direction: row;
            background-color: rgb(240, 240, 240);
            border-radius: 13px;
            padding: 15px 30px;
            min-height: $height - 30px;
            cursor: pointer;

            .question {
                color: #076BFF;
                width: 100%;
                margin: auto 0px;
                margin-right: 30px;
            }

            .action {
                width: 40px;
                height: 40px;
                margin: auto;
                transition: transform 0.3s ease;
            }
        }

        .answer-container {
            display: flex;
            background-color: white;
            border-radius: $b-radius;
            top: 100%;
            left: 0;
            z-index: 1;
            max-height: 0px;
            height: auto;
            overflow: hidden;
            transition: max-height 0.3s ease-in-out;

            .answer {
                margin: 10px 30px;
            }
        }
    }
}
@font-face {
  font-family: 'Paralucent';
  src: local('Paralucent-Medium'), url('./fonts/Paralucent-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'ParalucentCond';
  src: local('ParalucentCond-Medium'), url('./fonts/ParalucentCond-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'ParalucentLight';
  src: local('Paralucent-Light'), url('./fonts/Paralucent-Light.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'Poppins', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h3 {
    margin: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

.partner-logo {
  &.universal-assistance {
    .cls-1 {
      fill: #243c79;
    }
    .cls-2 {
      fill:#0895a4;
    }
  }

  &.affinity {
    .cls-1 {
      fill:#ea7e27;
    }
    .cls-2 {
      fill:#000000;
    }
  }

  &.assist-card {
    .cls-1 {
      fill: #C62828;
    }
  }
}

.select-field-container {
    $border-size: 1px;
    position: relative;
    display: inline-block;

    input, .selected {
        cursor: pointer;
    }

    input:focus {
        caret-color: transparent;
    }
  
    .selected {
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
        border-radius: 0.25rem;
    }
  
    .options {
        position: absolute;
        top: calc(100% - 15px);
        left: 0;
        z-index: 1;
        width: calc(100% - $border-size*2);
        max-height: 10rem;
        overflow-y: auto;
        background-color: #fff;
        border: $border-size solid #ccc;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  
    .option {
        padding: 0.5rem 1rem;
        cursor: pointer;
  
        &:hover {
            background-color: #f5f5f5;
        }
    }
}
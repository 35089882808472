.orders-container {
    display: flex;
    flex-direction: column;
    width: 1000px;
    margin: 100px auto;
    row-gap: 40px;

    .no-results {
        display: flex;
        width: 100%;
        height: 150px;
        background-color: rgb(240, 240, 240);

        .text-container {
            display: flex;
            flex-direction: column;
            margin: auto;

            .text, .title {
                text-align: center;
            }
    
            .title {
                color: #076BFF;
                font-size: 23px;
            }
    
            .text {
                color: #070434;
                font-size: 18px;
            }
        }
    }
}
@import '../../variables';

.checkout-container {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 100px auto;

    .title, .breadcrumb, .link {
        color: $lightblue;
    }

    .title {
        margin-bottom: 5px;
    }

    .link {
        height: 25px;
        font-size: 13px;
        text-decoration: underline;
        text-transform: none;
        cursor: pointer;
    }

    .header {
        display: flex;

        .breadcrumb {
            margin: 0 0 auto auto;
        }
    }

    .button {
        text-transform: none;

        &.add-passenger, &.place-order {
            margin-top: 20px;
            width: 100%;
            height: 60px;
            border-radius: 15px;
        }

        &.add-passenger {
            background-color: $lightblue;

            .plus-button {
                display: flex;
                justify-content: center;
                background-color: $lightgreen;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                margin: auto 10px;

                span {
                    position: absolute;
                    font-size: 20px;
                    top: 12px;
                }
            }
        }

        &.place-order {
            background-color: $lightgreen;
            font-size: 24px;
        }
    }

    .content {
        display: flex;
        column-gap: 30px;
        row-gap: 30px;
        
        .column {
            display: flex;
            flex-direction: column;
        }

        .main-content {
            .passengers-container {
                margin: 20px 0;
            }

            .billing-form-container {
                margin: 20px 0;
            }

            .payment-method-selector-container {
                margin: 30px 0;
                column-gap: 30px;
            }
        }

        .side-content {
            margin-top: 30px;
            row-gap: 30px;

            .terms-of-use {
                margin: 0 15px;
                font-size: 14px;
                text-align: justify;
                
                .terms-link {
                    font-weight: bold;
                }
            }
        }
    }
}
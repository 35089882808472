$width: 575px;

.insurance-type-box-container {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    width: 100%;
    max-width: $width;
    height: 300px;

    .background-image {
        display: flex;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 80px;
    }

    .insurance-type-box-body {
        margin: 50px;
        width: calc($width - 100px);
        height: 300px;
        display: flex;
        flex-direction: row;

        .column {
            display: flex;
            flex-direction: column;
        
            span, h2, h3 {
                color: white;
            }
    
            .title {
                margin-top: 10px;
                font-size: 28px;
            }
    
            .text {
                font-size: 16px;
            }
        }

        button {
            margin: 0 0 auto 25px;
            min-width: fit-content;
            background-color: rgb(146, 216, 40);
            padding: 5px 15px;
            border-radius: 7px;
            text-transform: none;
            font-weight: bold;
            font-size: 14px;
        }
    }
}
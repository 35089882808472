.footer-background {
    background-color: rgb(240, 240, 240);
    width: 100%;

    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: auto;
        padding: 50px 0px;
        width: 1200px;
        color: gray;

        a {
            color: gray;
        }

        span {
            margin: 5px 0px;
        }

        .title {
            color: #070434;
            font-weight: bold;
        }

        .option {
            margin-top: 10px;
        }

        .logo {
            width: 300px;
        }

        .content-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
            
            .content-container {
                margin-top: 0;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            max-width: 280px;
        }

        .payment-method-container {
            display: flex;
            flex-direction: column;
            margin-right: 30px;
        }

        .icons-container {
            display: flex;
            flex-direction: row;
            margin-top: 10px;

            .icon {
                width: 35px;
                height: 35px;
                margin-right: 15px;
            }
        }

        .copyrights {
            margin: auto;
            font-size: 17px;
        }

        .divider {
            margin: 40px -5px;
            border-color: gray;
        }
    }
}
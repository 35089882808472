.review-box-container {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: rgb(240, 240, 240);
    padding: 20px 30px;
    margin: 0px 20px;

    .content {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        word-wrap: break-word;
        text-align: justify;

        .icon {
            height: fit-content;
            width: 250px;
            margin-top: 10px;
            margin-right: 30px;

            .cls-1 {
                fill: rgb(190, 190, 190);
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .user-name {
            font-weight: bold;
            font-size: 20px;
        }

        .icon {
            height: 20px;
            width: 20px;
            margin-right: 4px;

            .cls-1 {
                fill: rgb(80, 80, 80);
            }
        }
    }
}
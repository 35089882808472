.background {
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #076BFF;

    .header {
        width: 100%;
        height: 60px;
        background-color: #070434;
        display: flex;

        a {
            color: white;
            margin: auto;
            font-size: 17px;
        }
    }
  
    .navigation {
        display: flex;
        width: 100%;
        max-width: 1300px;
        height: 100%;
        margin: 0px auto;
        justify-content: space-between;

        .logo-container {
            width: 70px;
            padding: 0px 25px;
            margin: auto 0px;

            .logo {
                height: 35px;

                .cls-1 {
                    fill: white;
                }
            }
        }
      
        .nav-links-container {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
      
            .nav-link {
                padding: 10px 15px;
                cursor: pointer;
                color: white;
                font-size: 19px;
          }
        }
    }
  }
  
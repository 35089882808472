.sign-up-form-container {
    width: 300px;
    margin: 100px auto !important;

    .sign-up-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: fit-content;
        margin: auto;

        .title {
            color: white;
            font-size: 20px;
            margin-bottom: 20px;
        }

        .footer {
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;

            .button {
                width: 45%;
                height: 40px;
                margin: auto 0px auto auto;
                padding: 5px 15px;
                border-radius: 7px;
                text-transform: none;
                font-weight: bold;
                font-size: 15px;
                background-color: #070434;
            }
        }
    }
}
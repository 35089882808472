.order-information-container {
    $blue: #076BFF;
    $darkblue: #070434;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 210px;
    background-color: rgb(240, 240, 240);
    padding: 0px;
    color: $darkblue;

    .header {
        display: flex;
        background-color: $blue;
        height: 50px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;

        .order-number, .order-date {
            margin: auto 40px;
            color: white;
            font-size: 19px;
        }

        .order-date {
            margin-left: auto;
        }
    }

    .basic-info-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: auto 0;

        .column {
            display: flex;
            flex-direction: column;
            
            .partner-logo {
                width: 150px;
                margin: auto;
        
                &.universal-assistance {
                    height: 80px;
                }
            }

            .sub-title {
                margin-top: 10px;
            }

            .button {
                width: 150px;
                height: 30px;
                background-color: $blue;
                padding: 5px 15px;
                border-radius: 7px;
                text-transform: none;
                font-weight: bold;
                font-size: 15px;

                &.details {
                    margin-top: 20px;
                }
            }
        }
    }
}
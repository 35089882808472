@import '../../variables';

.order-feedback-container {
    display: flex;
    flex-direction: row;
    margin: 200px auto;
    width: 1000px;

    .correct-icon {
        width: 30%;
        height: 200px;
        margin: auto 40px auto 0;

        .cls-2 {
            fill: none;
        }
    }

    .text-container {
        width: 70%;

        .title {
            color: $lightblue;
            font-size: 60px;
            margin: 20px 0;
        }
    }
}
.welcome-message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 50px 0;
    width: 700px;
    height: 300px;
    text-align: center;

    h1 {
        color: #076BFF;
        font-size: 50px;
        margin: 10px 0;
    }

    span {
        font-size: 18px;
        padding: 0 40px;
    }
}
.count-field-container {
    display: flex;
    flex-direction: column;
    color: white;
    margin: 0px 20px;

    .value-container {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .round-container {
            border-radius: 30px;
            border: 2px solid white;
            margin: 0px 10px 0px 10px;
            width: 25px;
            text-align: center;
            cursor: pointer;
        }
    }
}
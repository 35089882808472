.home-container {
    background-repeat: no-repeat;
    background-size: 1650px;
    background-position-x: center;

    .reviews-container {
        display: flex;
        flex-direction: column;

        h1 {
            margin: auto;
            max-width: 700px;
            color: #076BFF;
            font-size: 50px;
            font-weight: bold;
            text-align: center;
        }
    }
}
.advantage-box-container {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    background-color: white;

    .image {
        width: 120px;
        height: 120px;
        margin: auto;
    }

    span {
        text-align: center;
        padding-top: 15px;
    }

    .title {
        color: #076BFF;
        font-weight: bold;
        font-size: large;
    }

    .text {
        margin-bottom: 30px;
    }
}
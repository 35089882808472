.partners-showcase-container {
    display: flex;
    margin: 30px auto;
    width: 1000px;
    justify-content: space-around;

    .logo-container {
        margin: auto;
        
        .partner-logo {
            height: 120px;
            width: 150px;
    
            &.universal-assistance {
                height: 80px;
            }
    
            .cls-1, .cls-2 {
                fill: #aaa;
            }
        }
    }
}
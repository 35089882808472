.insurance-purchase-step-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 25%;

    .logo-container {
        min-height: 120px;
        
        img {
            width: 100px;
        }
    }

    .title {
        text-transform: uppercase;
        font-size: 24px;
    }

    .text {
        margin-top: 10px;
    }
}
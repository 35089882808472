.faq-grid-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1200px;
    margin: 100px auto 60px auto;

    .container {
        display: flex;
        flex-direction: column;
    }
}
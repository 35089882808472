@import '../../variables';

.menu-container {
    .button {
        width: fit-content;
        height: 40px;
        padding: 5px 15px;
        border-radius: 7px;
        font-size: 15px;
        background-color: rgb(240, 240, 240);
        color: black;
        text-transform: none;
        font-weight: bold;
    }
}

.dropdown-menu {
    .dropdown {
        display: flex !important;
        flex-direction: column;
        padding: 10px 20px;

        .option {
            list-style: none;
        }

        .option-list {
            padding: 10px 0 0 0;
        }

        .row {
            display: flex;
            column-gap: 40px;
        }

        .column {
            display: flex;
            flex-direction: column;
        }

        .title {
            margin-top: 0;
        }

        .buttons-container {
            margin-left: auto;
            column-gap: 20px;
            margin-top: 20px;

            .button {
                width: 130px;
                height: 35px;
                padding: 5px 05px;
                border-radius: 7px;
                text-transform: none;
                font-weight: bold;
                font-size: 15px;
            }

            .clear-btn {
                background-color: gray;
            }

            .apply-btn {
                background-color: $lightblue;
            }
        }
    }
}
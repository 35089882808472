$primary-color: #076BFF;
$dark-primary-color: #070434;

.basic-trip-searcher-container {
    display: flex;
    flex-direction: column;

    .form-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px 0px;

        .action {
            width: 250px;
            height: 40px;
            margin: auto 0px;
            background-color: $dark-primary-color;
            padding: 5px 15px;
            border-radius: 7px;
            text-transform: none;
            font-weight: bold;
            font-size: 15px;
        }
    }

    .title {
        color: $primary-color;
        font-weight: bold;
        margin: 0px;
    }

    .trip-duration {
        color: gray;
    }
}
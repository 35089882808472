.advantages-grid-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 50px auto;
    width: 1000px;
    height: 662px;

    .row-divider {
        width: 100%;
        height: 2px;
        background: linear-gradient(to left, transparent 0%, grey 50%, transparent 100%);
    }

    .column-divider {
        width: 2px;
        height: 100%;
        background: linear-gradient(to bottom, transparent 0%, grey 100%);

        &.reverse {
            background: linear-gradient(to top, transparent 0%, grey 100%);
        }
    }

    .row {
        display: flex;
        flex-direction: row;
    }
}
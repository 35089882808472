@import '../../variables';

.trip-searcher-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 30px 120px;
    width: 1000px;
    border-radius: 20px;
    background-color: $lightblue;

    .fields-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 20px;

        .column {
            display: flex;
            flex-direction: column;
            max-width: 260px;

            .payment-advertising-container {
                display: flex;
                flex-direction: row;
                width: 200px;
                margin-left: 15px;
    
                .credit-card-icon {
                    width: 50px;
                    height: 50px;
                    margin: auto;
                }
    
                .advertising {
                    color: white;
                    margin: auto 15px;
                }
            }

            .count-field-container {
                margin-bottom: 20px;
            }
    
            .search-button {
                width: 260px;
                height: 40px;
                margin: auto 0 0 0;
                background-color: $darkblue;
                padding: 5px 15px;
                border-radius: 7px;
                text-transform: none;
                font-weight: bold;
                font-size: 15px;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 15px 0px;
    }
}
@import '../../variables';

.calendar-picker {
    position: absolute;
    top: 100%;
    z-index: 1;
    font-family: sans-serif;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f0f0f0;
        padding: 8px;
        font-size: 18px;

        button {
            border: transparent;
            background-color: transparent;
            cursor: pointer;
        }
    }
  
    .days-of-week {
        display: flex;
        justify-content: space-between;
        background-color: #f0f0f0;
        padding: 8px;
    
        div {
            width: calc(100% / 7);
            text-align: center;
        }
    }
  
    .days {
        display: flex;
        flex-wrap: wrap;
        padding: 8px;
  
        .day {
            width: calc(100% / 7 - 16px);
            text-align: center;
            padding: 8px;

            &.valid {
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #f0f0f0;
                }
        
                &.selected {
                    background-color: $lightblue;
                    color: #fff;
                }
            }

            &.invalid {
                background-color: $lightgrey;
            }
        }
    }
}
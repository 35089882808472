.reviews-slider-container {
    $num-items: 6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 50px auto;

    .button {
        display: flex;
        margin: auto;
        margin-left: 30px;
        width: 15px;
        height: 15px;
        border-radius: 60px;
        background-color: #076BFF;
        cursor: pointer;
        padding: 20px;

        &.reverse {
            margin-right: 30px;
            margin-left: 0px;

            .icon {
                transform: rotate(180deg);
            }
        }

        .icon {
            .cls-1 {
                fill: white;
            }
        }
    }
}
$primary-color: #076BFF;
$dark-primary-color: #070434;
$small-font: 13px;
$big-font: 24px;

.insurance-offer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 20px;
    padding: 30px 50px;
    background-color: rgb(240, 240, 240);
    color: $dark-primary-color;

    .partner-container, .subscription-container, .health-container, .price-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-weight: bold;
            font-size: $small-font;
        }
    }

    .subscription-container, .health-container {
        .title {
            margin-bottom: 15px;
        }
    }

    .partner-container {
        width: 150px;

        .title {
            text-align: center;
        }

        .partner-logo {
            margin: auto;
            width: 100%;

            &.universal-assistance {
                width: 66%;
            }
        }
    }

    .subscription-container {
        width: 200px;
        
        .subscription, .covid, .covid-coverage {
            color: $primary-color;
        }

        .subscription, .covid-coverage {
            font-weight: bold;
        }

        .covid, .covid-coverage {
            font-size: $small-font;
        }
    }

    .health-container {
        width: 400px;
        margin-right: 50px;

        .value {
            font-weight: bold;
        }

        .full-subscription-info {
            color: $primary-color;
            font-size: $small-font;
            text-decoration: underline;
        }
    }

    .price-container {
        text-align: end;
        position: relative;
        padding-bottom: 50px;

        .title {
            margin-bottom: 30px;
        }

        .price {
            font-weight: bold;
            font-size: $big-font;
        }

        .price-description {
            color: $primary-color;
            font-size: $small-font;
            margin-bottom: 10px;
        }

        .buttons-container {
            display: flex;
            position: absolute;
            right: 0px;
            bottom: 0px;

            .compare-btn, .select-btn {
                height: 35px;
                margin-left: 20px;
                padding: 5px 15px;
                border-radius: 7px;
                text-transform: none;
                font-weight: bold;
                font-size: 15px;
            }

            .compare-btn {
                background-color: gray;
                min-width: 160px;
            }

            .select-btn {
                background-color: $primary-color;
                min-width: 180px;
            }
        }
    }
}
.mult-trip-container {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;

    .introduction-container, .welcome-message-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        text-align: center;

        h1 {
            font-size: 50px;
            margin: 10px 0;
        }
    }

    .welcome-message-container {
        padding-top: 200px;
        padding-bottom: 330px;
        width: 600px;

        h1, span {
            color: white;
        }

        span {
            font-size: 18px;
            padding: 0 40px;
        }
    }

    .introduction-container {
        margin: 150px auto;
        width: 930px;

        .highlighted {
            color: #076BFF;
            font-weight: bold;
        }

        h1 {
            margin-bottom: 50px;
        }

        span {
            margin: 15px 0px;
        }

        span, h3 {
            font-size: 23px;
        }
    }
}
.date-field-container {
    position: relative;
    display: inline-block;

    .calendar-picker {
        width: calc(100% - 2px);
        top: calc(100% - 15px);
    }

    input, .selected {
        cursor: pointer;
    }

    input:focus {
        caret-color: transparent;
    }
}
.user-information-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 30px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .information-container {
        display: flex;
        flex-direction: column;
        margin: auto 10px;
        color: white;

        .title {
            font-size: 17px;
        }

        .subtitle {
            font-size: 14px;
        }
    }
}
.blog-post-preview-container {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 350px;
    height: 350px;
    background-color: rgb(240, 240, 240);

    .image {
        width: 100%;
        height: 50%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    .preview-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 20px;

        .title {
            font-weight: bold;
            margin-top: 0px;
            font-size: 20px;
        }

        .preview {
            margin-top: 10px;
        }
    
        .action {
            margin-top: auto;
            font-weight: bold;
        }
    
        .action, .title {
            color: #076BFF;
        }
    }
}
.mt-subscription-box-container {
    display: flex;
    flex-direction: column;
    width: 260px;
    padding: 50px;
    border-radius: 20px;
    background-color: rgb(240, 240, 240);
    text-align: center;

    .header, .title, .price, .sub-price {
        color: #076BFF;
    }

    .header {
        margin-bottom: 20px;
    }

    span {
        font-size: 18px;
    }

    h1 {
        font-size: 29px;
    }

    .price {
        margin: 0px;
    }

    .advantage {
        margin-bottom: 20px;
    }

    .horizontal-divisor {
        border-bottom: 2px solid #076BFF;
        margin: 50px 0px;
    }

    .partner-logo {
        width: 150px;
        margin: 30px auto 50px auto;
    }

    .action {
        width: fit-content;
        height: 40px;
        margin: auto;
        background-color: #076BFF;
        padding: 5px 15px;
        border-radius: 7px;
        text-transform: none;
        font-weight: bold;
        font-size: 15px;
    }
}
.passenger-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;

    .input-field {
        width: 215px !important;
    }

    .button {
        &.remove {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            padding: 0;
            min-width: 0;
            margin: auto 0;
        }
    }
}
@import '../../variables';

.payment-method-selector-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .method-container {
        display: flex;
        flex-direction: column;
        width: 25%;

        .method-box {
            display: flex;
            height: 70px;
            width: 100%;
            border-radius: 12px;
            cursor: pointer;

            .title {
                margin: auto auto auto 0;
            }

            &.selected {
                background-color: $lightblue;
                border: 1px solid $lightblue;

                .title {
                    color: white;
                }

                .icon {
                    .cls-1, .cls-2 {
                        fill: white;
                    }

                    .cls-3 {
                        stroke: white;
                    }
                }
            }

            &.not-selected {
                border: $border; 

                .title {
                    color: lightgrey;
                }

                .icon {
                    .cls-1, .cls-2 {
                        fill: lightgrey;
                    }

                    .cls-3 {
                        stroke: lightgrey;
                    }
                }
            }

            .icon {
                margin: auto 10px auto auto;
                height: 60%;
                width: fit-content;

                &.pix {
                    margin: auto;
                }
            }
        }

        .discount-box {
            display: flex;
            height: 30px;
            width: 100%;
            background-color: $lightgreen;
            border-radius: 8px;
            margin-top: 10px;

            .text {
                color: white;
                font-size: 12px;
                margin: auto;
            }
        }
    }
}